const { Callbacks } = require("jquery");

function topicsSortAction(){
    
    $('.js-category-tabs__item').on('click', function(){
      $('.js-category-tabs__item').removeClass('is-current');
      $(this).addClass('is-current');
      $('.atc-wrap--tpc .atc').css('display', 'none');
      if($(this).hasClass('tab-all')) {
        $('.atc-wrap--tpc .atc').css('display', 'inline-block');
      } else if($(this).hasClass('tab-new')){
        $('.atc-wrap--tpc .atc-new').css('display', 'inline-block');
      } else if($(this).hasClass('tab-info')) {
        $('.atc-wrap--tpc .atc-info').css('display', 'inline-block');
      } else if($(this).hasClass('tab-info-reach')) {
        $('.atc-wrap--tpc .atc-info-reach').css('display', 'inline-block');
      }
    });
    
}

function mmbLoop() {
    const loopEl = $('.top-mmb-loop');
    loopEl.slick({
      autoplay: true, // 自動でスクロール
      autoplaySpeed: 0, // 自動再生のスライド切り替えまでの時間を設定
      speed: 5000, // スライドが流れる速度を設定
      cssEase: "linear", // スライドの流れ方を等速に設定
      swipe: false, // 操作による切り替えはさせない
      arrows: false, // 矢印非表示
      pauseOnFocus: false, // スライダーをフォーカスした時にスライドを停止させるか
      pauseOnHover: false, // スライダーにマウスホバーした時にスライドを停止させるか
      variableWidth: true,
      responsive: [
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 3, // 画面幅750px以下でスライド3枚表示
          }
        }
      ]
    });
}


function spMvHeight(){
  let spMvHeight= $(window).height();
  $('.top-mv-sec').outerHeight(spMvHeight);
}

function scrollPresentedAction() {
  const wTop = $(window).scrollTop();
  const logoR = $('.header__logo-wrap');
  const menu = $('.js-header__sp-nav-bar');
  const presents = $('.top-presents-cont');
  const menuApp = $('.js-menu-app');
  const contents = menuApp.offset().top;
  if(contents < wTop) {
    presents.addClass('is-scrolling');
    logoR.addClass('is-scrolling');
    menu.addClass('is-scrolling');
  } else {
    presents.removeClass('is-scrolling');
    logoR.removeClass('is-scrolling');
    menu.removeClass('is-scrolling');
  }
}

$(function(){
  topicsSortAction();
  //spMvHeight(); 
});

$(window).on('load', function(){
  mmbLoop();
});

$(window).on('load scroll', function(){
  scrollPresentedAction();
});

 